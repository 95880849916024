import {useState, useRef, useEffect} from "react";

import {useLocation, useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import secure from "../../../../utils/secure.js";
import {getApp} from "../../../../utils/rest.js";

// material-ui
import {useTheme} from "@mui/material/styles";
import {
  Avatar,
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "./../../../../ui-component/cards/MainCard";
import Transitions from "./../../../../ui-component/extended/Transitions";

// assets
import {IconLogout, IconSettings, IconWallet} from "@tabler/icons";
import blancImage from "../../../../assets/images/appBlanc.png";

// ==============================|| PROFILE MENU ||============================== //
import Auth from "./../../../../utils/auth.js";

import languagesLabels from "./../../../../languages/languages";

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector(state => state.customization);
  const location = useLocation();
  const navigate = useNavigate();
  const [roleLetter, setRoleLetter] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [appIcon, setAppIcon] = useState(blancImage);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    customization.language
  );

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  useEffect(() => {
    getRole();
    setEmail(Auth.getEmail());

    if (isMainPage()) {
      setLoading(false);
      setAppIcon(blancImage);
    } else if (
      location &&
      location.state &&
      location.state.app &&
      location.state.app._id
    ) {
      onGetApp(location.state.app._id);
    } else {
      setLoading(false);
      setAppIcon(blancImage);
    }
  }, [location]);

  useEffect(() => {}, [customization]);
  async function getRole() {
    let role = Auth.getRole();
    let rl = role.charAt(0).toUpperCase();
    setRoleLetter(rl);
  }

  function isMainPage() {
    return location.pathname === "/";
  }

  async function onGetApp() {
    let token = await Auth.getToken();

    try {
      let encrypted = secure.encrypt({
        idApp: location.state.app._id,
        token
      });
      let res = await axios.get(getApp, {
        params: {appsisto: encrypted},
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Credentials": true
        }
      });

      setAppIcon(res.data.app.icon);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function renderText(key) {
    let value = languagesLabels.signin[key];
    let label = value.find(l => l.language === selectedLanguage);
    return label.label;
  }
  const handleLogout = async () => {
    Auth.signout();
    navigate("/login");
  };
  const handleSettings = async () => {
    let user = await Auth.getUser();
    navigate("/profile", {state: {user, app: location.state.app}});
    setOpen(false);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        onClick={handleToggle}
        sx={{width: "200px", cursor: "pointer"}}
      >
        <Grid item>
          <Typography ref={anchorRef} sx={{color: theme.palette.text.primary}}>
            {Auth.getFullName() + " (" + Auth.getRole() + ")"}
          </Typography>
        </Grid>
        <Grid item>
          {loading ? (
            <CircularProgress size={40} sx={{marginLeft: 1}} />
          ) : (
            <Avatar
              sx={{
                width: 40,
                height: 40,
                marginLeft: 1,
                bgcolor: "white",
                color: theme.palette.text.primary,
                borderRadius: "50%"
              }}
              src={appIcon}
            />
          )}
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [30, 30]
              }
            }
          ]
        }}
        style={{zIndex: 1300}}
      >
        {({TransitionProps}) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={160}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <PerfectScrollbar
                    style={{
                      zIndex: 9999999999,
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden"
                    }}
                  >
                    <Box sx={{p: 2}}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 250,
                          minWidth: 200,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%"
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`
                          }}
                          selected={selectedIndex === 0}
                          onClick={handleSettings}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{color: theme.palette.text.primary}}
                              >
                                {renderText("accountSettings")}
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{color: theme.palette.text.primary}}
                              >
                                {renderText("signout")}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
