import {lazy} from "react";

// project imports
import MainLayout from "./../layout/MainLayout";
import Loadable from "./../ui-component/Loadable";

import Auth from "./../utils/auth.js";
// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("./../views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("./../views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("./../views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("./../views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("./../views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("./../views/utilities/TablerIcons"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("./../views/sample-page")));
const Users = Loadable(lazy(() => import("./../views/users/Users")));
const User = Loadable(lazy(() => import("./../views/user/User")));
const NewUser = Loadable(lazy(() => import("./../views/new-user/NewUser")));
const Subjects = Loadable(lazy(() => import("./../views/subjects/Subjects")));
const Subject = Loadable(lazy(() => import("./../views/subject/Subject")));
const NewSubject = Loadable(
  lazy(() => import("./../views/new-subject/NewSubject"))
);
const AppUserList = Loadable(
  lazy(() => import("./../views/app-user-list/AppUserList.js"))
);
const AppUsers = Loadable(lazy(() => import("./../views/app-users/AppUsers")));
const AppUser = Loadable(lazy(() => import("./../views/app-user/AppUser")));
const AppNewUser = Loadable(
  lazy(() => import("./../views/app-new-user/AppNewUser"))
);
const Apps = Loadable(lazy(() => import("./../views/apps/Apps")));
const App = Loadable(lazy(() => import("./../views/app/App")));
const NewApp = Loadable(lazy(() => import("./../views/new-app/NewApp")));
const NewScreen = Loadable(
  lazy(() => import("./../views/new-screen/NewScreen"))
);
const Screen = Loadable(lazy(() => import("./../views/screen/Screen")));
const Quiz = Loadable(lazy(() => import("./../views/quiz/Quiz")));
const QuizzesResults = Loadable(
  lazy(() => import("./../views/quizzes-results/QuizzesResults"))
);
const QuizResults = Loadable(
  lazy(() => import("./../views/quiz-results/QuizResults"))
);
const QuizResult = Loadable(
  lazy(() => import("./../views/quiz-result/QuizResult"))
);
const NewQuiz = Loadable(lazy(() => import("./../views/new-quiz/NewQuiz")));
const NewLeaflet = Loadable(
  lazy(() => import("./../views/new-leaflet/NewLeaflet"))
);
const NewMindfulness = Loadable(
  lazy(() => import("./../views/new-mindfulness/NewMindfulness"))
);
const Mindfulness = Loadable(
  lazy(() => import("./../views/mindfulness/Mindfulness"))
);
const NewMindfulnessPro = Loadable(
  lazy(() => import("./../views/new-mindfulness-pro/NewMindfulnessPro"))
);
const MindfulnessPro = Loadable(
  lazy(() => import("./../views/mindfulness-pro/MindfulnessPro"))
);
const NewMindfulnessTouch = Loadable(
  lazy(() => import("./../views/new-mindfulness-touch/NewMindfulnessTouch"))
);
const MindfulnessTouch = Loadable(
  lazy(() => import("./../views/mindfulness-touch/MindfulnessTouch"))
);
const NewMap = Loadable(lazy(() => import("./../views/new-map/NewMap")));
const Map = Loadable(lazy(() => import("./../views/map/Map")));

const NotificationsRegistered = Loadable(
  lazy(() =>
    import("./../views/notifications-registered/NotificationsRegistered")
  )
);
const NotificationsPush = Loadable(
  lazy(() => import("./../views/notifications-push/NotificationsPush"))
);
const NotificationsGeolocationPush = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-geolocation-push/NotificationsGeolocationPush"
    )
  )
);
const NotificationsGeolocationHistory = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-geolocation-history/NotificationsGeolocationHistory"
    )
  )
);
const NotificationsGeolocationPushZones = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-geolocation-push-zones/NotificationsGeolocationPushZones"
    )
  )
);
const NotificationsGeolocationPushZone = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-geolocation-push-zone/NotificationsGeolocationPushZone"
    )
  )
);
const NotificationsGeolocationPushNewZone = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-geolocation-push-new-zone/NotificationsGeolocationPushNewZone"
    )
  )
);
const NotificationsSms = Loadable(
  lazy(() => import("./../views/notifications-sms/NotificationsSms"))
);
const NotificationsEmail = Loadable(
  lazy(() => import("./../views/notifications-email/NotificationsEmail"))
);
const AppNotifications = Loadable(
  lazy(() => import("./../views/app-notifications/AppNotifications"))
);
const NotificationHistoryPush = Loadable(
  lazy(() =>
    import("./../views/notification-history-push/NotificationHistoryPush")
  )
);
const NotificationHistoryEmail = Loadable(
  lazy(() =>
    import("./../views/notification-history-email/NotificationHistoryEmail")
  )
);
const NotificationHistorySms = Loadable(
  lazy(() =>
    import("./../views/notification-history-sms/NotificationHistorySms")
  )
);

const NotificationScheduledPush = Loadable(
  lazy(() =>
    import("./../views/notification-scheduled-push/NotificationScheduledPush")
  )
);
const NotificationScheduledEmail = Loadable(
  lazy(() =>
    import("./../views/notification-scheduled-email/NotificationScheduledEmail")
  )
);
const NotificationScheduledSms = Loadable(
  lazy(() =>
    import("./../views/notification-scheduled-sms/NotificationScheduledSms")
  )
);

const FormResults = Loadable(
  lazy(() => import("./../views/form-results/FormResults"))
);
const FormResult = Loadable(
  lazy(() => import("./../views/form-result/FormResult"))
);
const NotificationAppearance = Loadable(
  lazy(() =>
    import("./../views/notification-appearance/NotificationAppearance")
  )
);
const NotificationSegments = Loadable(
  lazy(() => import("./../views/notification-segments/NotificationSegments"))
);
const Welcome = Loadable(lazy(() => import("./../views/welcome/Welcome")));

const AppSections = Loadable(
  lazy(() => import("./../views/app-sections/AppSections"))
);
const Notifications = Loadable(
  lazy(() => import("./../views/notifications/Notifications"))
);
const Screens = Loadable(lazy(() => import("./../views/screens/Screens")));

const AppManagement = Loadable(
  lazy(() => import("./../views/app-management/AppManagement"))
);

const Quizzes = Loadable(lazy(() => import("./../views/quizzes/Quizzes")));
const Forms = Loadable(lazy(() => import("./../views/forms/Forms")));
const Accomodation = Loadable(
  lazy(() => import("./../views/accomodation/Accomodation"))
);
const Owner = Loadable(lazy(() => import("./../views/owner/Owner")));
const Leaflets = Loadable(lazy(() => import("./../views/leaflets/Leaflets")));
const Leaflet = Loadable(lazy(() => import("./../views/leaflet/Leaflet")));
const AppExtras = Loadable(
  lazy(() => import("./../views/app-extras/AppExtras"))
);
const AppStatistics = Loadable(
  lazy(() => import("./../views/app-statistics/AppStatistics"))
);
const MindfulnessList = Loadable(
  lazy(() => import("./../views/mindfulness-list/MindfulnessList"))
);
const MindfulnessProList = Loadable(
  lazy(() => import("./../views/mindfulness-pro-list/MindfulnessProList"))
);
const MindfulnessTouchList = Loadable(
  lazy(() => import("./../views/mindfulness-touch-list/MindfulnessTouchList"))
);
const AppSettings = Loadable(
  lazy(() => import("./../views/app-settings/AppSettings"))
);
const AppBasicParameters = Loadable(
  lazy(() => import("./../views/app-basic-parameters/AppBasicParameters"))
);
const AppStudioSettings = Loadable(
  lazy(() => import("./../views/app-studio-settings/AppStudioSettings"))
);
const AppLanguages = Loadable(
  lazy(() => import("./../views/app-languages/AppLanguages"))
);
const AppPermissions = Loadable(
  lazy(() => import("./../views/app-permissions/AppPermissions"))
);
const AppIcon = Loadable(lazy(() => import("./../views/app-icon/AppIcon")));
const AppSplash = Loadable(
  lazy(() => import("./../views/app-splash/AppSplash"))
);
const AppSystemMessages = Loadable(
  lazy(() => import("./../views/app-system-messages/AppSystemMessages"))
);
const AppLogin = Loadable(lazy(() => import("./../views/app-login/AppLogin")));
const AppLikedMarker = Loadable(
  lazy(() => import("./../views/app-liked-marker/AppLikedMarker"))
);
const AppUserNotifications = Loadable(
  lazy(() => import("./../views/app-user-notifications/AppUserNotifications"))
);
const AppSearch = Loadable(
  lazy(() => import("./../views/app-search/AppSearch"))
);
const AppCoupon = Loadable(
  lazy(() => import("./../views/app-coupon/AppCoupon"))
);
const AppMaps = Loadable(lazy(() => import("./../views/app-maps/AppMaps")));
const AppPaid = Loadable(lazy(() => import("./../views/app-paid/AppPaid")));
const AppOta = Loadable(lazy(() => import("./../views/app-ota/AppOta")));
const AppWallet = Loadable(
  lazy(() => import("./../views/app-wallet/AppWallet"))
);
const AppEshop = Loadable(lazy(() => import("./../views/app-eshop/AppEshop")));
const AppAdmin = Loadable(lazy(() => import("./../views/app-admin/AppAdmin")));
const AppWebView = Loadable(
  lazy(() => import("./../views/app-webview/AppWebView"))
);
const Care = Loadable(lazy(() => import("./../views/care/Care")));
const ScreenSettings = Loadable(
  lazy(() => import("./../views/screen-settings/ScreenSettings"))
);
const ScreenBasicParameters = Loadable(
  lazy(() => import("./../views/screen-basic-parameters/ScreenBasicParameters"))
);
const ScreenDrawer = Loadable(
  lazy(() => import("./../views/screen-drawer/ScreenDrawer"))
);
const ScreenTabs = Loadable(
  lazy(() => import("./../views/screen-tabs/ScreenTabs"))
);
const ScreenBackgroundImage = Loadable(
  lazy(() => import("./../views/screen-background-image/ScreenBackgroundImage"))
);
const ScreenHeaderImage = Loadable(
  lazy(() => import("./../views/screen-header-image/ScreenHeaderImage"))
);
const ScreenEditor = Loadable(
  lazy(() => import("./../views/screen-editor/ScreenEditor"))
);
const VideoScreenEditor = Loadable(
  lazy(() => import("./../views/video-screen-editor/VideoScreenEditor"))
);
const Chat = Loadable(lazy(() => import("./../views/chat/Chat")));
const ChatRooms = Loadable(
  lazy(() => import("./../views/chat-rooms/ChatRooms"))
);
const ChatRoom = Loadable(lazy(() => import("./../views/chat-room/ChatRoom")));
const ChatSettings = Loadable(
  lazy(() => import("./../views/chat-settings/ChatSettings"))
);

const NewMap2 = Loadable(lazy(() => import("./../views/new-map2/NewMap2")));
const MapEdit = Loadable(lazy(() => import("./../views/map-edit/MapEdit")));
const StatisticsSms = Loadable(
  lazy(() => import("./../views/statistics-sms/StatisticsSms"))
);
const StatisticsEmail = Loadable(
  lazy(() => import("./../views/statistics-email/StatisticsEmail"))
);
const StatisticsAppDownload = Loadable(
  lazy(() => import("./../views/statistics-app-download/StatisticsAppDownload"))
);
const StatisticsAppUsage = Loadable(
  lazy(() => import("./../views/statistics-app-usage/StatisticsAppUsage"))
);
const StatisticsScreensUsage = Loadable(
  lazy(() =>
    import("./../views/statistics-screens-usage/StatisticsScreensUsage")
  )
);
const StatisticsComponentsUsage = Loadable(
  lazy(() =>
    import("./../views/statistics-components-usage/StatisticsComponentsUsage")
  )
);
const StatisticsUsers = Loadable(
  lazy(() => import("./../views/statistics-users/StatisticsUsers"))
);
const StatisticsNotifications = Loadable(
  lazy(() =>
    import("./../views/statistics-notifications/StatisticsNotifications")
  )
);
const Paros = Loadable(lazy(() => import("./../views/paros/Paros")));
const NewParo = Loadable(lazy(() => import("./../views/new-paro/NewParo")));
const Paro = Loadable(lazy(() => import("./../views/paro/Paro")));
const NewParoScreen = Loadable(
  lazy(() => import("./../views/new-paro-screen/NewParoScreen"))
);
const ParoScreen = Loadable(
  lazy(() => import("./../views/paro-screen/ParoScreen"))
);
const SmartNotificationsPush = Loadable(
  lazy(() =>
    import("./../views/smart-notification-push/SmartNotificationsPush")
  )
);
const NotificationsRegisteredSend = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-registered-send/NotificationsRegisteredSend"
    )
  )
);
const NotificationsRegisteredStreets = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-registered-streets/NotificationsRegisteredStreets"
    )
  )
);
const NotificationsRegisteredUsers = Loadable(
  lazy(() =>
    import(
      "./../views/notifications-registered-users/NotificationsRegisteredUsers"
    )
  )
);

const SmartNotificationsEmail = Loadable(
  lazy(() =>
    import("./../views/smart-notification-email/SmartNotificationsEmail")
  )
);

const SmartNotificationsSms = Loadable(
  lazy(() => import("./../views/smart-notification-sms/SmartNotificationsSms"))
);

const SmartScreens = Loadable(
  lazy(() => import("./../views/smart-screens/SmartScreens"))
);

const SmartMaps = Loadable(
  lazy(() => import("./../views/smart-maps/SmartMaps"))
);

const Profile = Loadable(lazy(() => import("./../views/profile/Profile")));

const SmartMap = Loadable(lazy(() => import("./../views/smart-map/SmartMap")));

const SmartCare = Loadable(
  lazy(() => import("./../views/smart-care/SmartCare"))
);
const SmartCoupons = Loadable(
  lazy(() => import("./../views/smart-coupons/SmartCoupons"))
);
const NewCoupon = Loadable(
  lazy(() => import("./../views/new-coupon/NewCoupon"))
);
const Coupons = Loadable(lazy(() => import("./../views/coupons/Coupons")));
const Coupon = Loadable(lazy(() => import("./../views/coupon/Coupon")));
const Templates = Loadable(
  lazy(() => import("./../views/templates/Templates"))
);
const CouponEditor = Loadable(
  lazy(() => import("./../views/coupon-editor/CouponEditor"))
);
const CouponAdmin = Loadable(
  lazy(() => import("./../views/coupon-admin/CouponAdmin"))
);
const CouponList = Loadable(
  lazy(() => import("./../views/coupon-list/CouponList"))
);
const ScreenQrcode = Loadable(
  lazy(() => import("./../views/screen-qrcode/ScreenQrcode"))
);

// ==============================|| MAIN ROUTING ||============================== //

let MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: "utils",
      children: [
        {
          path: "util-typography",
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: "utils",
      children: [
        {
          path: "util-color",
          element: <UtilsColor />
        }
      ]
    },
    {
      path: "utils",
      children: [
        {
          path: "util-shadow",
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: "icons",
      children: [
        {
          path: "tabler-icons",
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: "*",
      element: <DashboardDefault />
    },
    {
      path: "app-sections/app-management",
      element: <AppManagement />
    },
    {
      path: "app-sections/app-management/quizzes",
      element: <Quizzes />
    },
    {
      path: "app-sections/app-management/forms",
      element: <Forms />
    },
    {
      path: "app-sections/app-management/accommodation",
      element: <Accomodation />
    },
    {
      path: "app-sections/app-management/accommodation/owner",
      element: <Owner />
    },
    {
      path: "app-sections/app-management/leaflets",
      element: <Leaflets />
    },
    {
      path: "app-sections/app-management/leaflets/leaflet",
      element: <Leaflet />
    },
    {
      path: "app-sections/app-extras",
      element: <AppExtras />
    },
    {
      path: "app-sections/app-statistics",
      element: <AppStatistics />
    },
    {
      path: "app-sections/app-statistics/statistics-sms",
      element: <StatisticsSms />
    },
    {
      path: "app-sections/app-statistics/statistics-email",
      element: <StatisticsEmail />
    },
    {
      path: "app-sections/app-statistics/statistics-app-download",
      element: <StatisticsAppDownload />
    },
    {
      path: "app-sections/app-statistics/statistics-app-usage",
      element: <StatisticsAppUsage />
    },
    {
      path: "app-sections/app-statistics/statistics-screens-usage",
      element: <StatisticsScreensUsage />
    },
    {
      path: "app-sections/app-statistics/statistics-components-usage",
      element: <StatisticsComponentsUsage />
    },
    {
      path: "app-sections/app-statistics/statistics-users",
      element: <StatisticsUsers />
    },
    {
      path: "app-sections/app-statistics/statistics-notifications",
      element: <StatisticsNotifications />
    },
    {
      path: "app-sections/app-extras/mindfulness-list",
      element: <MindfulnessList />
    },
    {
      path: "app-sections/app-extras/mindfulness-pro-list",
      element: <MindfulnessProList />
    },
    {
      path: "app-sections/app-extras/mindfulness-touch-list",
      element: <MindfulnessTouchList />
    },
    {
      path: "app-sections/app-settings",
      element: <AppSettings />
    },
    {
      path: "app-sections/app-settings/app-basic-parameters",
      element: <AppBasicParameters />
    },
    {
      path: "app-sections/app-settings/app-studio-settings",
      element: <AppStudioSettings />
    },
    {
      path: "app-sections/app-settings/app-languages",
      element: <AppLanguages />
    },
    {
      path: "app-sections/app-settings/app-permissions",
      element: <AppPermissions />
    },
    {
      path: "app-sections/app-settings/app-icon",
      element: <AppIcon />
    },
    {
      path: "app-sections/app-settings/app-splash",
      element: <AppSplash />
    },
    {
      path: "app-sections/app-settings/app-system-messages",
      element: <AppSystemMessages />
    },
    {
      path: "app-sections/app-settings/app-login",
      element: <AppLogin />
    },
    {
      path: "app-sections/app-settings/app-liked-marker",
      element: <AppLikedMarker />
    },
    {
      path: "app-sections/app-settings/app-user-notifications",
      element: <AppUserNotifications />
    },
    {
      path: "app-sections/app-settings/app-coupon",
      element: <AppCoupon />
    },
    {
      path: "app-sections/app-settings/app-search",
      element: <AppSearch />
    },
    {
      path: "app-sections/app-settings/app-webview",
      element: <AppWebView />
    },
    {
      path: "app-sections/app-settings/app-paid",
      element: <AppPaid />
    },
    {
      path: "app-sections/app-settings/app-ota",
      element: <AppOta />
    },
    {
      path: "app-sections/app-settings/app-wallet",
      element: <AppWallet />
    },
    {
      path: "app-sections/app-settings/app-eshop",
      element: <AppEshop />
    },
    {
      path: "app-sections/care",
      element: <Care />
    },
    {
      path: "app-sections/smart-care",
      element: <SmartCare />
    },
    {
      path: "app-sections/app-settings/app-admin",
      element: <AppAdmin />
    },
    // {
    //   path: "app-maps",
    //   element: <AppMaps />,
    // },
    {
      path: "app-sections",
      element: <AppSections />
    },
    {
      path: "app-sections/smart-screens/new-screen",
      element: <NewScreen />
    },
    // {
    //   path: "app-sections/smart-screens/screen",
    //   element: <Screen />,
    // },
    // {
    //   path: "app-sections/screens",
    //   element: <Screens />,
    // },
    {
      path: "app-sections/smart-screens",
      element: <SmartScreens />
    },
    {
      path: "app-sections/app-management/quizzes/new-quiz",
      element: <NewQuiz />
    },
    {
      path: "app-sections/app-management/leaflets/new-leaflet",
      element: <NewLeaflet />
    },
    {
      path: "app-sections/app-management/quizzes/quiz",
      element: <Quiz />
    },
    {
      path: "app-sections/app-management/quizzes/quizzes-results",
      element: <QuizzesResults />
    },
    {
      path: "app-sections/app-management/quizzes/quiz-results",
      element: <QuizResults />
    },
    {
      path: "app-sections/app-management/quizzes/quiz-results/quiz-result",
      element: <QuizResult />
    },
    {
      path: "app-sections/app-management/forms/form-results",
      element: <FormResults />
    },
    {
      path: "app-sections/app-management/forms/form-results/form-result",
      element: <FormResult />
    },
    {
      path: "app-sections/app-extras/mindfulness-pro-list/new-mindfulness-pro",
      element: <NewMindfulnessPro />
    },
    {
      path: "app-sections/app-extras/mindfulness-pro-list/mindfulness-pro",
      element: <MindfulnessPro />
    },
    {
      path:
        "app-sections/app-extras/mindfulness-touch-list/new-mindfulness-touch",
      element: <NewMindfulnessTouch />
    },
    {
      path: "app-sections/app-extras/mindfulness-touch-list/mindfulness-touch",
      element: <MindfulnessTouch />
    },
    {
      path: "app-sections/app-extras/mindfulness-list/new-mindfulness",
      element: <NewMindfulness />
    },
    {
      path: "app-sections/app-extras/mindfulness-list/mindfulness",
      element: <Mindfulness />
    },
    {
      path: "new-map",
      element: <NewMap />
    },
    {
      path: "map",
      element: <Map />
    },
    {
      path: "app-sections/notifications/notifications-registered",
      element: <NotificationsRegistered />
    },
    {
      path:
        "app-sections/notifications/notifications-registered/notification-history-push",
      element: <NotificationHistoryPush />
    },
    {
      path:
        "app-sections/notifications/notifications-registered/notification-scheduled-push",
      element: <NotificationScheduledPush />
    },
    {
      path: "app-sections/notifications/notifications-push",
      element: <NotificationsPush />
    },
    {
      path: "app-sections/notifications/notifications-geolocation-push",
      element: <NotificationsGeolocationPush />
    },
    {
      path:
        "app-sections/notifications/notifications-geolocation-push/notifications-geolocation-history",
      element: <NotificationsGeolocationHistory />
    },
    {
      path:
        "app-sections/notifications/notifications-geolocation-push/notifications-geolocation-push-zones",
      element: <NotificationsGeolocationPushZones />
    },
    {
      path:
        "app-sections/notifications/notifications-geolocation-push/notifications-geolocation-push-zones/notifications-geolocation-push-zone",
      element: <NotificationsGeolocationPushZone />
    },
    {
      path:
        "app-sections/notifications/notifications-geolocation-push/notifications-geolocation-push-zones/notifications-geolocation-push-new-zone",
      element: <NotificationsGeolocationPushNewZone />
    },
    {
      path: "app-sections/notifications/notifications-email",
      element: <NotificationsEmail />
    },
    {
      path: "app-sections/notifications/notifications-sms",
      element: <NotificationsSms />
    },
    // {
    //   path: "app-sections/notifications/notifications-push/notification-push-send",
    //   element: <NotificationPushSend />,
    // },
    {
      path:
        "app-sections/notifications/notifications-push/smart-notification-push",
      element: <SmartNotificationsPush />
    },
    {
      path:
        "app-sections/notifications/notifications-registered/notifications-registered-send",
      element: <NotificationsRegisteredSend />
    },
    {
      path:
        "app-sections/notifications/notifications-registered/notifications-registered-streets",
      element: <NotificationsRegisteredStreets />
    },
    {
      path:
        "app-sections/notifications/notifications-registered/notifications-registered-users",
      element: <NotificationsRegisteredUsers />
    },
    {
      path: "app-sections/smart-notification-push",
      element: <SmartNotificationsPush />
    },
    {
      path:
        "app-sections/notifications/notifications-email/smart-notification-email",
      element: <SmartNotificationsEmail />
    },
    {
      path:
        "app-sections/notifications/notifications-sms/smart-notification-sms",
      element: <SmartNotificationsSms />
    },
    // {
    //   path: "app-sections/notifications/notifications-email/notification-email-send",
    //   element: <NotificationEmailSend />,
    // },
    // {
    //   path: "app-sections/notifications/notifications-sms/notification-sms-send",
    //   element: <NotificationSmsSend />,
    // },
    {
      path: "app-sections/app-settings/app-notifications",
      element: <AppNotifications />
    },
    {
      path:
        "app-sections/notifications/notifications-push/notification-history-push",
      element: <NotificationHistoryPush />
    },
    {
      path:
        "app-sections/notifications/notifications-email/notification-history-email",
      element: <NotificationHistoryEmail />
    },
    {
      path:
        "app-sections/notifications/notifications-sms/notification-history-sms",
      element: <NotificationHistorySms />
    },
    {
      path:
        "app-sections/notifications/notifications-push/notification-scheduled-push",
      element: <NotificationScheduledPush />
    },
    {
      path:
        "app-sections/notifications/notifications-email/notification-scheduled-email",
      element: <NotificationScheduledEmail />
    },
    {
      path:
        "app-sections/notifications/notifications-sms/notification-scheduled-sms",
      element: <NotificationScheduledSms />
    },
    // {
    //   path: "notification-appearance",
    //   element: <NotificationAppearance />,
    // },
    // {
    //   path: "notification-segments",
    //   element: <NotificationSegments />,
    // },
    {
      path: "app-sections/app-settings/welcome",
      element: <Welcome />
    },
    {
      path: "app-sections/notifications",
      element: <Notifications />
    },
    {
      path: "app-sections/smart-screens/screen-settings",
      element: <ScreenSettings />
    },
    {
      path:
        "app-sections/smart-screens/screen-settings/screen-basic-parameters",
      element: <ScreenBasicParameters />
    },
    {
      path: "app-sections/smart-screens/screen-settings/screen-drawer",
      element: <ScreenDrawer />
    },
    {
      path: "app-sections/smart-screens/screen-settings/screen-tabs",
      element: <ScreenTabs />
    },
    {
      path:
        "app-sections/smart-screens/screen-settings/screen-background-image",
      element: <ScreenBackgroundImage />
    },
    {
      path: "app-sections/smart-screens/screen-settings/screen-header-image",
      element: <ScreenHeaderImage />
    },
    {
      path: "app-sections/smart-screens/screen-settings/screen-editor",
      element: <ScreenEditor />
    },
    {
      path: "app-sections/smart-screens/screen-settings/video-screen-editor",
      element: <VideoScreenEditor />
    },
    {
      path: "app-sections/app-management/chat",
      element: <Chat />
    },
    {
      path: "app-sections/app-management/chat/chat-rooms",
      element: <ChatRooms />
    },
    {
      path: "app-sections/app-management/chat/chat-rooms/chat-room",
      element: <ChatRoom />
    },
    {
      path: "app-sections/app-management/chat/chat-settings",
      element: <ChatSettings />
    },
    {
      path: "app-sections/app-management/smart-maps/coupons",
      element: <Coupons />
    },
    {
      path: "app-sections/app-management/smart-maps/coupons/coupon",
      element: <Coupon />
    },
    {
      path: "app-sections/app-management/smart-maps/coupons/coupon-admin",
      element: <CouponAdmin />
    },
    {
      path:
        "app-sections/app-management/smart-maps/coupons/coupon-admin/coupon-editor",
      element: <CouponEditor />
    },
    {
      path:
        "app-sections/app-management/smart-maps/coupons/coupon-admin/coupon-list",
      element: <CouponList />
    },
    {
      path: "app-sections/app-management/smart-coupons/new-coupon",
      element: <NewCoupon />
    },
    {
      path: "app-sections/app-management/smart-coupons/coupons",
      element: <Coupons />
    },
    {
      path: "app-sections/app-management/smart-coupons/coupons/coupon",
      element: <Coupon />
    },

    {
      path: "app-sections/app-management/smart-maps",
      element: <SmartMaps />
    },
    {
      path: "app-sections/smart-maps",
      element: <SmartMaps />
    },
    {
      path: "app-sections/app-management/smart-maps/smart-map",
      element: <SmartMap />
    },
    {
      path: "app-sections/smart-maps/smart-map",
      element: <SmartMap />
    },
    {
      path: "app-sections/app-management/smart-maps/new-map2",
      element: <NewMap2 />
    },
    {
      path: "app-sections/app-management/smart-coupons",
      element: <SmartCoupons />
    },
    {
      path: "app-sections/smart-maps/new-map2",
      element: <NewMap2 />
    },
    {
      path: "app-sections/app-management/smart-maps/map-edit",
      element: <MapEdit />
    },
    {
      path: "app-sections/smart-maps/map-edit",
      element: <MapEdit />
    },
    {
      path: "app-sections/app-management/paros",
      element: <Paros />
    },
    {
      path: "app-sections/app-management/paros/new-paro",
      element: <NewParo />
    },
    {
      path: "app-sections/app-management/paros/paro",
      element: <Paro />
    },
    {
      path: "app-sections/app-management/paros/paro/new-paro-screen",
      element: <NewParoScreen />
    },
    {
      path: "app-sections/app-management/paros/paro/paro-screen",
      element: <ParoScreen />
    },
    {
      path: "app-sections/templates",
      element: <Templates />
    },
    {
      path: "app-sections/app-management/smart-coupons/coupons/coupon-editor",
      element: <CouponEditor />
    },
    {
      path: "app-sections/app-management/smart-coupons/coupons/coupon-admin",
      element: <CouponAdmin />
    },
    {
      path:
        "app-sections/app-management/smart-coupons/coupons/coupon-admin/coupon-editor",
      element: <CouponEditor />
    },
    {
      path:
        "app-sections/app-management/smart-coupons/coupons/coupon-admin/coupon-list",
      element: <CouponList />
    },
    {
      path: "app-sections/smart-screens/screen-settings/screen-qrcode",
      element: <ScreenQrcode />
    }
  ]
};

if (Auth.getRole() === "root") {
  MainRoutes.children.push({
    path: "subjects",
    element: <Subjects />
  });
  MainRoutes.children.push({
    path: "subject",
    element: <Subject />
  });
  MainRoutes.children.push({
    path: "new-subject",
    element: <NewSubject />
  });
  MainRoutes.children.push({
    path: "/users",
    element: <Users />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/users",
    element: <Users />
  });
  MainRoutes.children.push({
    path: "/profile",
    element: <Profile />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/users/user",
    element: <User />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/users/new-user",
    element: <NewUser />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list",
    element: <AppUserList />
  });
  MainRoutes.children.push({
    path: "/app-users",
    element: <AppUsers />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/app-users",
    element: <AppUsers />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/app-users/app-user",
    element: <AppUser />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/app-users/app-new-user",
    element: <AppNewUser />
  });
  MainRoutes.children.push({
    path: "new-app",
    element: <NewApp />
  });
} else if (Auth.getRole() === "admin") {
  MainRoutes.children.push({
    path: "users",
    element: <Users />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/users/user",
    element: <User />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/users",
    element: <Users />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/users/new-user",
    element: <NewUser />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/app-users/app-user",
    element: <AppUser />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/app-users",
    element: <AppUsers />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list/app-users/app-new-user",
    element: <AppNewUser />
  });
  MainRoutes.children.push({
    path: "app-sections/app-user-list",
    element: <AppUserList />
  });
  MainRoutes.children.push({
    path: "profile",
    element: <Profile />
  });
  MainRoutes.children.push({
    path: "user",
    element: <User />
  });
  MainRoutes.children.push({
    path: "new-user",
    element: <NewUser />
  });
  MainRoutes.children.push({
    path: "app-user-list",
    element: <AppUserList />
  });
  MainRoutes.children.push({
    path: "app-users",
    element: <AppUsers />
  });
  MainRoutes.children.push({
    path: "app-user",
    element: <AppUser />
  });
  MainRoutes.children.push({
    path: "app-new-user",
    element: <AppNewUser />
  });
  MainRoutes.children.push({
    path: "new-app",
    element: <NewApp />
  });
}

export default MainRoutes;
